import { Box, Heading, Image, Text } from '@hometime-ui/core'
import { navigate } from 'gatsby'
import bookingConfirmedIcon from '~/images/booking_graphic.svg'
import React, { useEffect } from 'react'

export default function BookDemoSuccess() {
  const isBrowser = () => typeof window !== 'undefined'

  useEffect(() => {
    const bookingStatus =
      isBrowser && localStorage.getItem('calendarStatus') === 'true'

    if (!bookingStatus) {
      navigate('/contact-us/')
    } else {
      window.gtag('event', 'calender_booking_success')
      isBrowser() && localStorage.setItem('calendarStatus', false)
    }
  }, [])

  return (
    <>
      <Box
        textAlign="center"
        d="inline-block"
        maxW="544px"
        mx="auto"
        mt={{ base: 10, md: 14 }}
        mb="6"
      >
        <Heading level="3" mb="2">
          Your profile is setup! Book an onboarding call to access the platform.
        </Heading>
        <Text color="gray.700">
          We personally onboard every new user to ensure you are set up for
          success in the first 30 minutes.
        </Text>
        <Box
          maxW="410px"
          bg="white"
          mx="auto"
          mt={{ base: 6, lg: 12 }}
          py={{ base: 6, lg: 12 }}
          shadow="md"
          borderRadius="md"
        >
          <Image
            w="140px"
            mx="auto"
            src={bookingConfirmedIcon}
            mb={{ base: 4, md: 6 }}
          />
          <Heading level="3" mb="2">
            Booking confirmed
          </Heading>
          <Text color="gray.700">You&apos;re booked with Hometime.</Text>
          <Text color="gray.700">An invitation has been emailed to you.</Text>
        </Box>
      </Box>
    </>
  )
}
